import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Flex, Box, Text } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "flex"
    }}>{`Flex`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Flex p={10} bg=\"primary\">\n  <Box p={20} bg=\"white\">\n    Left\n  </Box>\n  <Box p={20} bg=\"white\" flex={1}>\n    <Text textAlign=\"center\">Middle</Text>\n  </Box>\n  <Box p={20} bg=\"white\">\n    Right\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Box,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex p={10} bg="primary" mdxType="Flex">
    <Box p={20} bg="white" mdxType="Box">
      Left
    </Box>
    <Box p={20} bg="white" flex={1} mdxType="Box">
      <Text textAlign="center" mdxType="Text">Middle</Text>
    </Box>
    <Box p={20} bg="white" mdxType="Box">
      Right
    </Box>
  </Flex>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Flex} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      